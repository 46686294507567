@import '../../styles/theme.scss';

@mixin wrapper() {
  display: flex;
  min-height: 100vh;
}

@mixin menu() {
  padding: 10px;
  padding-top: 30px;
  min-width: 300px;
  height: 100vh;
  border-inline-end-color: $border-color !important;

  @media screen and (max-width: 1024px) {
    width: 100px;
  }
}

@mixin menu-groupTitle() {
  font-weight: 800;
  padding-bottom: 4px;
}

@mixin main() {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 850px;
  margin: 100px;
  margin-top: 70px;
  margin-right: 0px;

  @media (max-width: 896px) {
    margin: 100px 20px;
  }
}

@mixin main--fullWidth() {
  margin-right: 120px;
  max-width: unset;
}

@mixin pageInfo() {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-bottom: 1px solid $border-color;
  padding-bottom: 20px;
  margin-bottom: 35px;
}

@mixin pageTitle() {
  margin: 0px;
  font-size: 36px;
  font-weight: 800;
  line-height: 1;
}

@mixin breadcrumb-li() {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;

  &:first-child {
    position: relative;
    bottom: 1px;
  }
}

@mixin breadcrumb-li--separator() {
  margin-inline: 7px;
}

@mixin breadcrumb-li-a() {
  display: flex;
  justify-content: center;
  align-items: center
}

.wrapper {
  @include wrapper();

  main {
    @include main();

    &:is(.full) {
      @include main--fullWidth();
    }
  }

  :global {
    .ant-menu {
      @include menu();

      .ant-menu-item-group-title {
        @include menu-groupTitle();
      }
    }
  }

  .page-info {
    @include pageInfo();

    &>h1 {
      @include pageTitle();
    }

    :global(.ant-breadcrumb) li {
      @include breadcrumb-li();

      &:is(:global(.ant-breadcrumb-separator)) {
        @include breadcrumb-li--separator();
      }

      &>a {
        @include breadcrumb-li-a()
      }
    }
  }

}