@import '../../../../../../styles/theme.scss';

@mixin table() {
  border-radius: 6px;
  border: 1px solid $border-color-2;
  box-shadow:
    rgb(0, 0, 0) 0px 0px 0px 0px,
    rgba(21, 21, 21, 0.08) 0px 1px 2px 0px,
    rgba(21, 21, 21, 0.08) 0px 2px 4px 0px;
}

@mixin headItem() {
  font-weight: 700 !important;
  text-align: center !important
}

@mixin bodyItem() {
  text-align: center
}

.table {
  @include table();

  thead th {
    @include headItem();
  }

  tbody tr {
    @include bodyItem();
  }
}