@import '../../../../../../../styles/theme.scss';

@mixin wrapper() {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

@mixin inputWrapper() {
  max-width: 250px;
  padding-block: 0px !important;
  height: 36px;
  border-radius: 6px;
  background-color: $background-color-solid;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px, rgba(21, 21, 21, 0.08) 0px 1px 2px 0px;

  &:is(:focus-within) {
    box-shadow: 0px 0px 0px 1.5px #547dd8
  }
}

@mixin inputWrapper-prefix() {
  position: relative;
  bottom: 1px
}

@mixin input() {
  font-size: 16px;
  line-height: 1;

  &::placeholder {
    color: rgb(black, .45);
  }
}

@mixin button() {
  height: 36px;
  color: rgb(18, 70, 123);
  background-color: rgb(227, 239, 251);
  font-weight: bold;
  border: none;
  border-radius: 6px;
  column-gap: 6px !important;

  &:hover {
    background-color: #C7DFF7 !important
  }

  &:active {
    background-color: #97C3F0 !important
  }
}

@mixin button-icon() {
  position: relative;
  top: 2px
}

.wrapper {
  @include wrapper();

  button {
    @include button();

    :global(.ant-btn-icon) {
      @include button-icon();
    }
  }

  :global {
    .ant-input-affix-wrapper {
      @include inputWrapper();

      .ant-input-prefix {
        @include inputWrapper-prefix();
      }

      input {
        @include input();
      }
    }
  }
}