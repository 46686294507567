@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,500..1000;1,6..12,200..1000&display=swap');
@import '../../styles/theme.scss';

* {
  font-family: 'Nunito Sans', sans-serif;
  outline: none;
  list-style-type: none;
  box-sizing: border-box;
}

.formWrapper {
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.illustrationWrapper {
  width: 45%;
  background: #f3f5f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  .illustration {
    width: 60%;
  }

  @media (max-width: 768px) {
    display: none;
  }
}
