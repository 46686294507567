@import '../../../../../../styles/theme.scss';

@mixin modal() {}

@mixin content() {
  border: 1px solid $border-color-2;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px, rgba(21, 21, 21, 0.08) 0px 2px 8px -2px, rgba(21, 21, 21, 0.08) 0px 12px 16px -4px;
  border-radius: 8px;
}

@mixin headerTitle-title() {
  margin-block: 0px;
  font-size: 18px;
  font-weight: 700;
}

@mixin headerTitle-description() {
  color: #757575;
  margin-top: 0px;
  font-weight: 500;
  font-size: 16px
}

@mixin formItemLabel() {
  padding-bottom: 3px !important;
  padding-left: 3px !important;
  font-size: 13px;
  font-weight: 600;
}

@mixin datePicker() {
  padding: 0px 12px;
  border-radius: 6px;
  box-shadow:
    rgb(0, 0, 0) 0px 0px 0px 0px,
    rgba(21, 21, 21, 0.08) 0px 1px 2px 0px;
  border-color: $border-color-2 !important;
  width: 100%;
  height: 36px;
  min-height: unset;

  &:is(:focus-within) {
    box-shadow: 0px 0px 0px 1.5px #547dd8;
    border-color: #547dd8;
  }
}

@mixin datePicker-placeholder() {
  color: rgb(black, .4);
}

@mixin footer() {}

@mixin footerButton() {
  border-radius: 3.5px;
  height: 36px;
}

@mixin footerButton-span() {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  font-size: 15px;
  font-weight: 600
}

@mixin dropdown() {
  border-radius: 6px !important;
  border: 1px solid $border-color-2;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px, rgba(21, 21, 21, 0.08) 0px 2px 8px -2px, rgba(21, 21, 21, 0.08) 0px 12px 16px -4px;
}

@mixin dropdown-footerButton() {
  border-radius: 5px;
  padding-inline: 10px;
  height: 25px;
}

@mixin dropdownContent() {
  border-collapse: unset;
}

@mixin dropdownLayout() {
  background-color: #fbfcfe;
}

@mixin dropdownContent-cell() {
  padding: 0 !important;
}

@mixin dropdownContent-cell-innerCell() {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 6px;
}

@mixin dropdownItem--selected() {
  background-color: rgb(230, 237, 253) !important;
  border-radius: 6px
}

.modal {
  @include modal();

  :global {
    .ant-modal-content {
      @include content();

      .ant-modal-header .ant-modal-title {
        p {
          &:first-child {
            @include headerTitle-title();
          }

          &:last-child {
            @include headerTitle-description();
          }
        }
      }

      .ant-form-item-label {
        @include formItemLabel();
      }

      .ant-picker {
        @include datePicker();

        input::placeholder {
          @include datePicker-placeholder();
        }
      }
    }

    .ant-modal-footer {
      @include footer();

      button {
        @include footerButton();

        span {
          @include footerButton-span();
        }
      }
    }
  }
}

:global {
  .ant-picker-panel-container {
    @include dropdown();

    .ant-picker-panel-layout {
      @include dropdownLayout();

      .ant-picker-date-panel {
        .ant-picker-content {
          @include dropdownContent();
        }

        .ant-picker-cell {
          @include dropdownContent-cell();

          .ant-picker-cell-inner {
            @include dropdownContent-cell-innerCell();
          }
        }
      }

      .ant-picker-time-panel .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
        @include dropdownItem--selected()
      }

      .ant-picker-footer button {
        @include dropdown-footerButton();
      }
    }
  }
}