@import '../../../../../styles/theme.scss';

@mixin form() {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background-color: $background-color-solid;
  border: 1px solid $border-color;
  border-radius: 6px;
  padding: 25px;
  box-shadow:
    rgb(0, 0, 0) 0px 0px 0px 0px,
    rgba(21, 21, 21, 0.08) 0px 1px 2px 0px,
    rgba(21, 21, 21, 0.08) 0px 2px 4px 0px;
}

@mixin info() {
  display: flex;
  align-items: center;
  column-gap: 50px;
}

@mixin formItem() {
  padding: 0;
  margin: auto 0px;
}

@mixin formItemLabel() {
  padding-bottom: 2px !important;
}

@mixin formItemLabel-label() {
  height: unset;
  margin-inline-start: 4px;
}

@mixin input() {
  padding: 0px 12px;
  border-radius: 6px;
  box-shadow:
    rgb(0, 0, 0) 0px 0px 0px 0px,
    rgba(21, 21, 21, 0.08) 0px 1px 2px 0px;
  border-color: $border-color-2 !important;
  width: 100%;
  height: 36px;
  min-height: unset;

  &:is(:focus-within) {
    box-shadow: 0px 0px 0px 1.5px #547dd8;
    border-color: #547dd8;
  }
}

@mixin submitButton() {
  font-size: larger;
  padding: 20px;
  margin-top: 20px;
}

.form {
  @include form();

  &>:global(.ant-form-item) {
    @include formItem();

    :global(.ant-form-item-label) {
      @include formItemLabel();

      &>label {
        @include formItemLabel-label()
      }
    }

    :global {
      .ant-input-affix-wrapper {
        @include input();
      }
    }
  }

  button {
    @include submitButton();
  }
}