@import '../../../../../styles/theme.scss';

@mixin form() {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  background-color: $background-color-solid;
  border: 1px solid $border-color;
  border-radius: 6px;
  padding: 25px;
  padding-bottom: 0px;
  box-shadow:
    rgb(0, 0, 0) 0px 0px 0px 0px,
    rgba(21, 21, 21, 0.08) 0px 1px 2px 0px,
    rgba(21, 21, 21, 0.08) 0px 2px 4px 0px;
}

@mixin info() {
  display: flex;
  align-items: center;
  column-gap: 50px;
}

@mixin fields() {
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 10px;
}

@mixin formItem() {
  padding: 0;
  margin: auto 0px;
}

@mixin formItemLabel() {
  padding-bottom: 2px;
}

@mixin formItemLabel-label() {
  height: unset;
  margin-inline-start: 4px;
}

@mixin input() {
  padding: 0px 12px;
  border-radius: 6px;
  box-shadow:
    rgb(0, 0, 0) 0px 0px 0px 0px,
    rgba(21, 21, 21, 0.08) 0px 1px 2px 0px;
  border-color: $border-color-2 !important;
  width: 100%;
  height: 36px;
  min-height: unset;

  &:is(:focus-within) {
    box-shadow: 0px 0px 0px 1.5px #547dd8;
    border-color: #547dd8;
  }
}

@mixin input--skeleton() {
  width: 100%;
}

@mixin submitButton() {
  font-size: larger;
  padding: 20px;
}

.form {
  @include form();

  &>.info {
    @include info();

    &>.fields {
      @include fields();

      &>:global(.ant-form-item) {
        @include formItem();

        @include formItemLabel();

        &>label {
          @include formItemLabel-label()
        }

        input {
          @include input();
        }

      }

      &>:global(.ant-skeleton-element .ant-skeleton-input) {
        @include input--skeleton()
      }

    }
  }

  button {
    @include submitButton();
  }
}