$font-family-primary: 'Nunito Sans', sans-serif;
$background-color-body: #f2f3f7;
$background-color-solid: #fbfcfe;
$background-color-input: white;
$background-color-div: #ffffff;
$background-color-hover: #e7e7e74d;
$text-color-primary: #2d313d;
$text-color-secondary: #8190a0;
$background-color-button-primary: #4773bf;
$background-color-button-secondary: #ffffff;
$text-color-button-primary: #ffffff;
$text-color-button-secondary: #4773bf;
$font-size-large: 34px;
$font-size-regular: 16px;
$font-size-small: 12px;
$font-weight-bold: 700;
$font-weight-light: 400;
$border-width: 0.125rem;
$border-color: #636b7433;
$border-color-2: #cdd7e1;
$border-radius-input: 0.3rem;
$border-radius-button: 0.5rem;
$input-border-color: #cdd7e1;
$error-color: #ff4d4f;
$warning-color: #faad14;
$link-color: #1890ff;
$outline-primary-color: #4773bf;