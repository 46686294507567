@import '../../styles/theme.scss';

.registerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $text-color-primary;
  width: 100%;
  padding: 30px;

  :global {
    .ant-form-item-explain-error {
      max-width: 435px;

      @media (max-width: 1024px) {
        max-width: 300px;
      }
    }
  }

  .textContainer {
    text-align: center;

    .title {
      font-size: $font-size-large !important;
      margin: 0;
    }

    .text {
      font-size: $font-size-regular;
      color: $text-color-secondary;
      margin: 0 0 15px 0;
    }
  }

  .registerForm {
    min-width: 40%;

    .formItem {
      padding: 0;
      margin: 20px 0;
    }

    div {
      padding: 0;
      margin: 0;
    }

    .inputField {
      font-size: $font-size-regular;
      background-color: $background-color-input;
      line-height: 2;
      padding: 0px 12px;
      border-radius: 6px;
      box-shadow:
        rgb(0, 0, 0) 0px 0px 0px 0px,
        rgba(21, 21, 21, 0.08) 0px 1px 2px 0px;
      border-color: 1px solid $border-color-2;
      width: 100%;
      min-height: unset;
      width: 435px;

      &:is(:focus-within) {
        box-shadow: 0px 0px 0px 1.5px #547dd8
      }

      input {
        padding: 10px;
      }

      input,
      div,
      span,
      div {
        background-color: $background-color-input;

        &:focus,
        &:active,
        &::content {
          background-color: #fff;
        }
      }

      &:is(:global(.ant-input-status-error)) {
        border-color: $error-color !important;

        &:is(:focus-within) {
          box-shadow: 0px 0px 0px 1px $error-color;
          border-color: $error-color;
        }
      }

      @media (max-width: 1024px) {
        max-width: 300px;
      }
    }

    .signUpBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      width: 100%;
    }

    .loginText {
      text-align: center;
      color: $text-color-secondary;
    }
  }
}

.feedback {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
