@mixin footerItem() {
  text-align: right;
  padding: 10px 8px !important;
}

@mixin footerWrapper() {
  display: flex;
  justify-content: flex-end;
}

@mixin footerWrapper-button() {
  background-color: #fbfcfe
}

@mixin footerWrapper-select-selector() {
  background-color: #fbfcfe;
  padding-left: 10px;
  padding-right: 5px;
  text-align: start;
}

@mixin footerWrapper-select-arrow() {
  top: 49%;
  inset-inline-end: 7px;
}

@mixin footerWrapper-text() {
  font-size: 13px
}

@mixin dropdown() {
  border-radius: 4px !important;
}

@mixin dropdown-item() {
  border-radius: 4px !important;
}

@mixin dropdown-item--selected() {
  background-color: rgb(230, 237, 253) !important;
}

.footer {
  :global {
    @include footerItem();

    .ant-space {
      @include footerWrapper();

      button {
        @include footerWrapper-button();
      }

      .ant-select {
        .ant-select-selector {
          @include footerWrapper-select-selector();
        }

        .ant-select-arrow {
          @include footerWrapper-select-arrow();
        }
      }

      .ant-typography {
        @include footerWrapper-text();
      }
    }
  }
}

:global {
  .ant-select-dropdown {
    @include dropdown();

    .ant-select-item {
      @include dropdown-item();

      &:is(.ant-select-item-option-selected) {
        @include dropdown-item--selected();
      }
    }
  }
}